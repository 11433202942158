import React from 'react'

const Coupon = () => {

    function handleSubmit(e) {
        e.preventDefault();
        const status = document.getElementById('status-coupon');
        const mail = document.getElementById('mail-coupon');
        const url = `https://api.espresso-tutorials.com/api/newsletter/subscribe?locale=en`;

        if (status) {
            status.innerText = '';
            status.classList.remove('text-white');
            status.classList.remove('border-red-500');
            status.classList.remove('bg-red-400');
            status.classList.remove('p-4');
            status.classList.remove('mt-4');
            status.classList.remove('rounded-md');
            status.classList.remove('text-sm');
        }

        if (!mail || !mail?.value) {
            status.innerText = 'Error: Please check your details and try again!';
            status.classList.add('text-white');
            status.classList.add('border-red-500');
            status.classList.add('bg-red-400');
            status.classList.add('p-4');
            status.classList.add('mt-4');
            status.classList.add('rounded-md');
            status.classList.add('text-sm');
            return;
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ email: mail.value }),
        }).then((response) => {
            if (response.ok) {
                return response;
            }
            return Promise.reject(response);
        }).then(() => {
            status.innerHTML = '<div class="block text-white border-green-500 bg-green-400 border border-solid p-4 mt-4 rounded-md text-sm leading-tight"><p>Thank you very much! You have successfully subscribed to the newsletter, as a thank you you will receive a 10% discount on the digital subscription for our SAP learning platform!</p><p class="mt-2">Coupon code: <span class="font-bold">10PEROFFIC</span></p></div>';
            mail.value = '';
            setTimeout(() => {
                status.innerHTML = '';
            }, 50000);
        }).catch((error) => {
            status.innerText = 'Error: Please check your details and try again!';
            status.classList.add('text-white');
            status.classList.add('border-red-500');
            status.classList.add('bg-red-400');
            status.classList.add('p-4');
            status.classList.add('mt-4');
            status.classList.add('rounded-md');
            status.classList.add('text-sm');
            setTimeout(() => {
                status.innerText = '';
                status.classList.remove('mt-4');
                status.classList.remove('text-white');
                status.classList.remove('border-red-500');
                status.classList.remove('bg-red-400');
                status.classList.remove('p-4');
                status.classList.remove('mt-4');
                status.classList.remove('rounded-md');
                status.classList.remove('text-sm');
            }, 5000);
        });
    }

    return (
        <div className="mt-16 bg-blue-200 rounded-lg md:rounded-xl p-8 relative">
            <span className="absolute top-0 left-0 bg-orange-500 text-white text-xs font-semibold py-2 px-3 rounded-lg leading-none shadow -mt-3 ml-4">
                10% discount
            </span>
            <h2 className="text-xl text-gray-900 leading-tight">
                <span className="font-semibold">Get a 10% discount now!</span> Sign up for our newsletter and receive a 10% discount on the digital subscription for our SAP learning platform!
            </h2>
            <form className="mt-8" onSubmit={handleSubmit}>
                <div className="">
                    <label className="sr-only" htmlFor="mail-coupon">Email</label>
                    <div className="flex flex-row items-center">
                        <input id="mail-coupon" className="flex-1 appearance-none text-sm py-3 focus:outline-none rounded-lg bg-white text-gray-800 px-4 placeholder-gray-600" type="text" placeholder="Add mail address"/>
                        <button type="submit" className="w-32 text-center ml-4 appearance-none bg-blue-500 hover:bg-orange-500 font-semibold text-white leading-none p-4 rounded-lg text-sm cursor-pointer">
                            subscribe
                        </button>
                    </div>
                </div>
            </form>
            <div id="status-coupon" className="w-full text-sm font-semibold"></div>
        </div>
    )
}

export default Coupon
